@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .content-container {
        @apply m-0 p-8 bg-white h-[100vh]
    }

    .sidebar-icon {
        @apply relative flex items-center justify-center rounded-full my-2 text-textPrimary h-12 w-12
        hover:bg-secondary hover:text-white hover:shadow-lg
        transition-all duration-100 ease-linear
        cursor-pointer;
    }

    .sidebar-icon.active {
        @apply bg-secondary text-white shadow-lg
    }

    .sidebar-tooltip {
        @apply absolute w-auto p-2 m-2 min-w-max lg:left-16
        rounded-md shadow-md
        text-white bg-secondary
        text-xs font-bold
        transition-all duration-100 scale-0 origin-left
    }

    .accordion {
        @apply bg-blue-400 text-white p-4 rounded-2xl my-2
        text-sm uppercase font-bold
        transition-all duration-100 ease-linear
    }

    .accordion-content {
        @apply w-auto my-2 min-w-max left-16 flex
        text-white
        text-xs font-bold
        transition-all duration-100 ease-linear scale-100 justify-center origin-top
    }

    .accordion-content-item {
        @apply bg-blue-300 rounded-2xl shadow-md p-4
    }

    .button {
        @apply flex items-center justify-center rounded-full px-12 py-4 my-2
        default:bg-primary text-white shadow-lg
        hover:brightness-110
        cursor-pointer
        transition-all duration-100 ease-linear
    }

    .input {
        @apply p-3 appearance-none bg-white text-blue-400 placeholder-blue-400 outline-0 font-bold
        rounded-xl
        transition-all duration-100 ease-linear
    }

    .main-input {
        @apply appearance-none bg-transparent outline-0 placeholder-blue-400 font-bold lg:text-5xl text-2xl
    }

    .tabs-container {
        @apply grid lg:grid-cols-2 gap-4 place-items-center h-[100%] w-[100%] bg-primary rounded-3xl
    }

    .tab {
        @apply lg:h-[100%] lg:flex hidden justify-center items-center
    }

    .active {
        @apply flex
    }

    .main-input-content {
        @apply grid lg:grid-rows-1 grid-rows-3
        lg:px-0 px-8
        lg:grid-cols-3 grid-cols-1
        place-items-center h-full
    }

    .type-select-sidebar {
        @apply col-span-1 shadow-lg rounded-full bg-white lg:h-[50%] px-2 flex lg:flex-col justify-center
    }

    .main-input-container {
        @apply col-span-2 flex flex-col w-[100%] row-span-2
    }

    .qr-code-image {
        @apply w-32 h-32 m-2
    }

    .tab-button-container {
        @apply lg:hidden fixed scale-0 bottom-[4.5rem] right-12
        transition-all duration-100 origin-right
    }

    .tab-change-button {
        @apply absolute top-[-15px] right-[-15px] rounded-full text-white bg-secondary p-3
    }

    .input-error {
        @apply animate-wiggle border-2 border-red-400 rounded-lg !placeholder-red-500
    }

    .loading-button {
        animation: animate 2s infinite;
    }

    @keyframes animate {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(720deg);
        }
    }
}